@tailwind base;
@tailwind components;
@tailwind utilities;

/* SF Pro Font */
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');
body {
  font-family: 'SF Pro Display', sans-serif;
  overscroll-behavior-y: none;
  background-color: #F8F8F8;
}

html {
  scroll-behavior: smooth;
}

.menu-btn .bar1, .menu-btn .bar2, .menu-btn .bar3 {
  width: 27px;
  height: 4px;
  background-color: #00A97F;
  margin: 4px 0;
  transition: 0.4s;
  border-radius: 999px;
}

/* Rotate first bar */
.menu-btn.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-7px, 7px);
  transform: rotate(-45deg) translate(-6px, 7px);
}

/* Fade out the second bar */
.menu-btn.open .bar2 {
  opacity: 0;
}

/* Rotate third bar */
.menu-btn.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-4px, -6px);
  transform: rotate(45deg) translate(-4px, -6px);
}
